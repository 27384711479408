.btn-custom {
  min-width: 120px;
  //border-radius: 50rem !important;
}

.btn-dotted {
  //background: unset;
  //border: 2px dotted #AABDF4;
  //color: #AABDF4;
}

.btn-outline-warning {
  border-color: #F49A41;
  color: #F49A41;
}

.btn-outline-warning:hover, .btn-outline-warning.active {
  background: darken(#F49A41, 10) !important;
  border-color: darken(#F49A41, 10) !important;
  outline: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-primary{
  background: #1462b3;
  border-color: #1462b3;
}
