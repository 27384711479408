@charset "UTF-8";
/** All toolbar buttons are inside of .ql-formats */
.ql-formats button {
  position: relative;
  /** Set a tooltip with css pseudo-elements, when buttons are hover, active or focus  */
}
.ql-formats button:hover::after, .ql-formats button:active::after, .ql-formats button:focus::after {
  background: #0d1e42;
  color: white;
  padding: 0.5em;
  border-radius: 0.4em;
  top: -120%;
  left: -10px;
  z-index: 999999;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
}

/** Set tooltip content for bold button **/
.ql-bold:hover::after, .ql-bold:active::after, .ql-bold:focus::after {
  content: "ตัวหนา";
}

.ql-italic:hover::after, .ql-italic:active::after, .ql-italic:focus::after {
  content: "ตัวเอียง";
}

.ql-underline:hover::after, .ql-underline:active::after, .ql-underline:focus::after {
  content: "ขีดเส้นใต้";
}

.ql-strike:hover::after, .ql-strike:active::after, .ql-strike:focus::after {
  content: "ขีดทับ";
}

.ql-link:hover::after, .ql-link:active::after, .ql-link:focus::after {
  content: "การเชื่อมโยงหลายมิติ";
}

.ql-blockquote:hover::after, .ql-blockquote:active::after, .ql-blockquote:focus::after {
  content: "Quote";
}

.ql-ql-header[value="1"]:hover::after, .ql-ql-header[value="1"]:active::after, .ql-ql-header[value="1"]:focus::after {
  content: "Header 1";
}

.ql-ql-header[value="2"]:hover::after, .ql-ql-header[value="2"]:active::after, .ql-ql-header[value="2"]:focus::after {
  content: "Header 2";
}

.ql-list[value=bullet]:hover::after, .ql-list[value=bullet]:active::after, .ql-list[value=bullet]:focus::after {
  content: "Bulleted List";
}

.ql-list[value=ordered]:hover::after, .ql-list[value=ordered]:active::after, .ql-list[value=ordered]:focus::after {
  content: "Numbered List";
}

.ql-script[value=sub]:hover::after, .ql-script[value=sub]:active::after, .ql-script[value=sub]:focus::after {
  content: "ตัวห้อย";
}

.ql-script[value=super]:hover::after, .ql-script[value=super]:active::after, .ql-script[value=super]:focus::after {
  content: "ตัวยก";
}

.ql-indent[value="-1"]:hover::after, .ql-indent[value="-1"]:active::after, .ql-indent[value="-1"]:focus::after {
  content: "Text Indent -1";
}

.ql-indent[value="+1"]:hover::after, .ql-indent[value="+1"]:active::after, .ql-indent[value="+1"]:focus::after {
  content: "Text Indent +1";
}