//ref https://github.com/KillerCodeMonkey/ngx-quill/issues/235#issuecomment-922725191
/** All toolbar buttons are inside of .ql-formats */
.ql-formats button {
  position: relative;
  /** Set a tooltip with css pseudo-elements, when buttons are hover, active or focus  */
  &:hover::after,
  &:active::after,
  &:focus::after {
    background: #0d1e42;
    color: white;
    padding: 0.5em;
    border-radius: 0.4em;
    top: -120%;
    left: -10px;
    z-index: 999999;
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
  }
}
/** Set tooltip content for bold button **/
.ql-bold {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "ตัวหนา";
  }
}

.ql-italic {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "ตัวเอียง";
  }
}

.ql-underline {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "ขีดเส้นใต้";
  }
}
.ql-strike {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "ขีดทับ";
  }
}
.ql-link {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "การเชื่อมโยงหลายมิติ";
  }
}
.ql-blockquote {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Quote";
  }
}
.ql-ql-header[value="1"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Header 1";
  }
}
.ql-ql-header[value="2"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Header 2";
  }
}
.ql-list[value="bullet"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Bulleted List";
  }
}
.ql-list[value="ordered"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Numbered List";
  }
}
.ql-script[value="sub"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "ตัวห้อย";
  }
}
.ql-script[value="super"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "ตัวยก";
  }
}
.ql-indent[value="-1"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Text Indent -1";
  }
}
.ql-indent[value="+1"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Text Indent +1";
  }
}
//.ql-size {
//  &:hover::after,
//  &:active::after,
//  &:focus::after {
//    content: "ขนาดตัวอักษร";
//  }
//}
//.ql-header {
//  &:hover::after,
//  &:active::after,
//  &:focus::after {
//    content: "หัวข้อ";
//  }
//}
//.ql-color {
//  &:hover::after,
//  &:active::after,
//  &:focus::after {
//    content: "สีตัวอักษร";
//  }
//}
//.ql-background {
//  &:hover::after,
//  &:active::after,
//  &:focus::after {
//    content: "พื้นหลัง";
//  }
//}
//
//.ql-align {
//  &:hover::after,
//  &:active::after,
//  &:focus::after {
//    content: "Align";
//  }
//}
