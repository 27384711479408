/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$primary: #3b57e8;
//$secondary:     $yellow-300;
//$success: #00E4BA;
$success: #2AC195;
//$info: $cyan;
$info: rgb(58 87 232 / 10%);
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$violet: #712cf9;
$default: #dee2e6;
//$default: rgb(0 0 0 / 25%);
$cold-steel: #f8f7f4;
//$active-color: #f0f3fa;
//$active-bg: #f0f3fa;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$custom-colors: (
  "violet": $violet,
  "info": $info,
  "default": $default
  //"active": $active
);

$nav-pills-link-active-bg: rgb(58 87 232 / 10%);
$nav-pills-link-active-color: #3b57e8;
$nav-link-font-weight: 500;

//$table-color: #3b57e8;
$table-bg: white;
//$table-striped-color: #3b57e8;
$table-striped-order: even;
//$table-striped-bg-factor: .05;
//$table-striped-bg: rgb(58 87 232 / 10%);
$table-striped-bg: #f8f9fa;


@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "customs";
@import "custom_forms";
@import "custom_buttons";
@import "custom_utility";
@import "custom_pagination";
@import "custom_tables";
@import "custom_toast";
@import "validate";

@each $color, $value in $custom-colors {
  .btn-#{$color} {
    @if $color == "default" {
      @include button-variant(
          white,
        $border: $default
      );
    } @else if $color == "info" {
      @include button-variant(
        $background: $info,
        $color: $primary,
        $border: $info
      );
    } @else {
      @include button-variant(
          $value,
          $value,
      );
    }
  }
}

//#212bb7
@each $color, $value in $custom-colors {
  .btn-outline-#{$color} {
    @if $color == "active" {
      @include button-outline-variant($value);
    } @else {
      @include button-outline-variant($value);
    }
  }
}

.btn-default {
  border-bottom: 1px solid #dee2e6 !important;
}
